import { useLocation, Navigate, Outlet } from "react-router-dom";
// app routes
import { APP_ROUTES, IS_USER_AUTHENTICATED_FLAG } from "../../common/constants";
// hooks
import useLocalStorage from "../../hooks/useLocalStorage";

const RequireAuth = () => {
  const location = useLocation();
  
  const [isUserAuthenticated] = useLocalStorage(IS_USER_AUTHENTICATED_FLAG);

  return isUserAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={APP_ROUTES.LOGIN} state={{ from: location }} replace />
  );
};

export default RequireAuth;
