export const processStoreTemplates = (store, template) => ({
  ...store,
  ...(store?.settings && {
    settings: {
      ...store.settings,
      templates: {
        ...store.settings.templates,
        [template.type]: {
          ...template,
        },
      },
    },
  }),
});
