import actionTypes from "../common/actionTypes";
import { processStoreTemplates } from "./utils";

const storeReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_STORE:
      return {
        ...state,
        loading: true,
        storeNotFound: false,
        store: null,
      };

    case actionTypes.SEARCH_STORE_SUCCESS:
      return {
        ...state,
        store: {
          ...action.payload,
          isSellingPlanUIDisabled: action.payload.isSellingPlanUIDisabled
            ? action.payload.isSellingPlanUIDisabled
            : false,
          hasSellingPlanEnabled: action.payload.hasSellingPlanEnabled
            ? action.payload.hasSellingPlanEnabled
            : false,
          hasNewBillingPlans: action.payload.hasNewBillingPlans
            ? action.payload.hasNewBillingPlans
            : false,
          isAppBlockEnabled: action.payload.isAppBlockEnabled
            ? action.payload.isAppBlockEnabled
            : false,
          subscription: {
            ...action.payload.subscription,
            isOldUnlimited: action.payload.subscription.isOldUnlimited
              ? action.payload.subscription.isOldUnlimited
              : false,
            hasUnlimitedVideoSupport: action.payload.subscription
              .hasUnlimitedVideoSupport
              ? action.payload.subscription.hasUnlimitedVideoSupport
              : false,
          },
        },
        loading: false,
      };

    case actionTypes.SEARCH_STORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_STORE:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.UPDATE_STORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.SEARCHED_STORE_NOT_FOUND:
      return {
        ...state,
        loading: false,
        storeNotFound: true,
        store: null,
      };

    case actionTypes.FETCH_STORE_TEMPLATE_SUCCESS:
      return {
        ...state,
        store: processStoreTemplates(state.store, action.payload),
      };

    case actionTypes.FETCH_STORE_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default storeReducer;
