import { BrowserRouter } from 'react-router-dom';
// routes
import Routes from "./routes";
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
// contexts
import { AuthProvider } from './contexts/AuthContext';
import { StoresProvider } from './contexts/StoresContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

export default function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <StoresProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <ScrollToTop />
              <Routes />
            </BrowserRouter>
          </CollapseDrawerProvider>
        </StoresProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
