export const APP_ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  STORES: "/stores"
};

export const IS_USER_AUTHENTICATED_FLAG = "is_user_authenticated";

export const SEARCH_DELAY = 1000;

export const SETTING_TYPES = {
  PO: "PO",
  BIS: "BIS",
  CS: "CS",
  IS: "IS",
}