const storesActionTypes = {
  // search store
  SEARCH_STORE: "SEARCH_STORE",
  SEARCH_STORE_SUCCESS: "SEARCH_STORE_SUCCESS",
  SEARCH_STORE_FAILURE: "SEARCH_STORE_FAILURE",

  // update store
  UPDATE_STORE: "UPDATE_STORE",
  UPDATE_STORE_SUCCESS: "UPDATE_STORE_SUCCESS",
  UPDATE_STORE_FAILURE: "UPDATE_STORE_FAILURE",

  // store not found
  SEARCHED_STORE_NOT_FOUND: "SEARCHED_STORE_NOT_FOUND",

  // fetch store template
  FETCH_STORE_TEMPLATE: "FETCH_STORE_TEMPLATE",
  FETCH_STORE_TEMPLATE_SUCCESS: "FETCH_STORE_TEMPLATE_SUCCESS",
  FETCH_STORE_TEMPLATE_FAILURE: "FETCH_STORE_TEMPLATE_FAILURE",
};

export default storesActionTypes;
