import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// material
import { styled, useTheme } from "@mui/material/styles";
import { Box, Drawer, Typography, Avatar, Stack } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../Logo";
import Scrollbar from "../Scrollbar";
import NavSection from "../NavSection";
import CollapseButton from "./CollapseButton";
//
import navConfig from "./NavConfig";
// hooks
import useAuth from "../../hooks/useAuthContext";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
//
import { cssStyles, NAVBAR } from "../../theme/cssStyles";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const AccountStyle = styled("div")(({ theme, isCollapse }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    backgroundColor: "transparent",
  }),
}));

const UserBoxStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isCollapse",
})(({ isCollapse }) => ({
  marginLeft: 16,
  overflow: "hidden",
  transition: (theme) =>
    theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  ...(isCollapse && {
    marginLeft: 0,
    width: 0,
  }),

  "& p, h6": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

Sidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function Sidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const theme = useTheme();

  const { user } = useAuth();

  const isDesktop = useResponsive("up", "lg");

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Logo isCollapsed={isCollapse} />

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>

        <AccountStyle isCollapse={isCollapse}>
          <Avatar src={"../../../static/avatar_default.jpg"} alt="photoURL" />

          <UserBoxStyle isCollapse={isCollapse}>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {user?.displayName || "Timesact Support"}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {user?.email}
            </Typography>
          </UserBoxStyle>
        </AccountStyle>
      </Stack>

      <NavSection navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: NAVBAR.DASHBOARD_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
