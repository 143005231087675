import {
  createContext,
  useEffect,
  useReducer,
  useCallback
} from 'react';
// firebase
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";
// service firebase
import { auth } from '../services/firebase';
// actions
import actionTypes from "../common/actionTypes";
// reducer
import { authReducer } from '../reducers';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// constants
import { IS_USER_AUTHENTICATED_FLAG } from '../common/constants';

const initialState = {
  loading: false,
  error: null,
  user: null,
};

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const [,setIsUserAuthenticated] = useLocalStorage(IS_USER_AUTHENTICATED_FLAG, false);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch({
          type: actionTypes.LOGIN_SUCCESS,
          payload: {
            user: {
              displayName: user.displayName,
              email: user.email,
            },
          },
        });

        setIsUserAuthenticated(true);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setIsUserAuthenticated]);

  const login = useCallback(
    async ({ email, password }) => {
      try {
        dispatch({ type: actionTypes.LOGIN });

        await signInWithEmailAndPassword(auth, email, password)
      } catch (error) {
        dispatch({
          type: actionTypes.LOGIN_FAILURE,
          payload: error
        });
      }
    },
    [],
  );

  const logout = useCallback(async () => {
    await signOut(auth);

    dispatch({ type: actionTypes.LOGOUT });
    setIsUserAuthenticated(false);
  }, [setIsUserAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };